export const groupingKey = 'group_by'

export const customFields = {
  root: [
    'id',
    'type',
    'desc',
    'number',
    'status',
    'seal_number',
    'discharged_at',
    'pickup_status',
    'delivery_status',
    'pickup_time',
    'delivery_time',
    'inspection_datetime',
    'gate_out',
    'portbase_enabled',
    'pod_customs_documents_present',
    'pod_customs_inspection_type',
    'pod_customs_inspection_status',
    'actual_first_day_of_demurrage',
    'pickup_address',
    'delivery_address',
    'pickup_transporter',
    'delivery_transporter',
  ],
  shipment: [
    'id',
    'title',
    'status',
    'vessel',
    'terminal',
    'modality',
    'load_type',
    'shippers',
    'consignees',
    'loading_port',
    'discharge_port',
    'shared_reference',
    'estimated_arrival',
    'estimated_departure',
    'estimated_discharge',
    'shipment_organizations',
    'carrier_estimated_arrival',
    'terminal_estimated_arrival',
    'house_bl_numbers',
    'master_bl_number',
    'closing_datetime',
    'closing_datetime_status',
  ],
}

export const groups = [
  {
    label: 'vessels_page.groups.shipments',
    value: 'shipment',
  },
  {
    label: 'vessels_page.groups.vessels',
    value: 'vessel',
  },
]

export const entityGroupMap = {
  shipment: 'container.shipment.title',
  vessel: 'container.shipment.vessel.name',
}

export const tableStorageKey = 'overviewTableState'

export const viewsLocalStorageKey = `${tableStorageKey}_views`

export const savedViewLocalStorageKey = `${tableStorageKey}_viewId`

export const subItemsPerPage = 20

export const tableSortingKeys = {
  containerNumber: 'container.number',
  shipmentVoyageNumber: 'container.shipment.voyage_number',
  containerStatus: 'container.status_name',
  containerPodCustomsDocumentsPresent:
    'container.pod_customs_documents_present',
  shipmentStatus: 'container.shipment.status.name',
  shipmentEstimatedDeparture: 'container.shipment.estimated_departure',
  shipmentEstimatedArrival: 'container.shipment.estimated_arrival',
  shipmentEstimatedDischarge: 'container.shipment.estimated_discharge',
  shipmentLoadingPort: 'container.shipment.loading_port.name',
  shipmentDischargePort: 'container.shipment.discharge_port.name',
  shipmentTerminal: 'container.shipment.terminal.name',
  containerPickupStatus: 'container.pickup_status',
  containerPickupTime: 'container.pickup_time',
  containerDeliveryStatus: 'container.delivery_status',
  containerDeliveryTime: 'container.delivery_time',
  containerSealNumber: 'container.seal_number',
  shipmentTitle: 'container.shipment.title',
  shipmentVessel: 'container.shipment.vessel.name',
  containerDischargeAt: 'container.discharge_at',
  shipmentTerminalEstimatedArrival:
    'container.shipment.terminal_estimated_arrival',
  containerGateOut: 'container.gate_out',
  containerInspectionDatetime: 'container.inspection_datetime',
  containerPodCustomsInspectionStatus:
    'container.pod_customs_inspection_status',
  containerPodCustomsInspectionType: 'container.pod_customs_inspection_type',
  containerActualFirstDayOfDemurrage: 'container.actual_first_day_of_demurrage',
  shipmentCarrierEstimatedArrival:
    'container.shipment.carrier_estimated_arrival',
  shipmentModality: 'container.shipment.modality',
  shipmentLoadType: 'container.shipment.load_type',
  containerType: 'container.type.name',
  shipmentConsignees: 'container.shipment.consignees',
  shipmentShippers: 'container.shipment.shippers',
  shipmentSharedReference: 'container.shipment.shared_reference',
  containerDeliveryAddress: 'container.delivery_address',
  containerPickupAddress: 'container.pickup_address',
  houseBlNumbers: 'container.shipment.house_bl_numbers',
  masterBlNumber: 'container.shipment.master_bl_number',
  pickupTransporter: 'container.pickup_transporter.name',
  deliveryTransporter: 'container.delivery_transporter.name',
  shipmentClosingDateTime: 'container.shipment.closing_datetime',
}

export const defaultColumnOrder = [
  tableSortingKeys.shipmentVoyageNumber,
  tableSortingKeys.containerType,
  tableSortingKeys.containerNumber,
  tableSortingKeys.containerStatus,
  tableSortingKeys.containerPodCustomsDocumentsPresent,
  tableSortingKeys.shipmentStatus,
  tableSortingKeys.shipmentClosingDateTime,
  tableSortingKeys.shipmentEstimatedDeparture,
  tableSortingKeys.shipmentEstimatedArrival,
  tableSortingKeys.shipmentEstimatedDischarge,
  tableSortingKeys.shipmentLoadingPort,
  tableSortingKeys.shipmentDischargePort,
  tableSortingKeys.shipmentTerminal,
  tableSortingKeys.containerPickupStatus,
  tableSortingKeys.containerPickupTime,
  tableSortingKeys.containerDeliveryStatus,
  tableSortingKeys.containerDeliveryTime,
  tableSortingKeys.containerSealNumber,
  tableSortingKeys.containerPickupAddress,
  tableSortingKeys.containerDeliveryAddress,
  tableSortingKeys.shipmentConsignees,
  tableSortingKeys.shipmentShippers,
  tableSortingKeys.shipmentTitle,
  tableSortingKeys.shipmentVessel,
  tableSortingKeys.shipmentSharedReference,
  tableSortingKeys.containerDischargeAt,
  tableSortingKeys.shipmentTerminalEstimatedArrival,
  tableSortingKeys.containerGateOut,
  tableSortingKeys.containerInspectionDatetime,
  tableSortingKeys.containerPodCustomsInspectionStatus,
  tableSortingKeys.containerPodCustomsInspectionType,
  tableSortingKeys.containerActualFirstDayOfDemurrage,
  tableSortingKeys.shipmentCarrierEstimatedArrival,
  tableSortingKeys.shipmentModality,
  tableSortingKeys.shipmentLoadType,
  tableSortingKeys.houseBlNumbers,
  tableSortingKeys.masterBlNumber,
  tableSortingKeys.pickupTransporter,
  tableSortingKeys.deliveryTransporter,
]

export const defaultColumnVisibility = {
  [tableSortingKeys.shipmentVoyageNumber]: true,
  [tableSortingKeys.containerType]: true,
  [tableSortingKeys.containerNumber]: true,
  [tableSortingKeys.containerStatus]: true,
  [tableSortingKeys.containerPodCustomsDocumentsPresent]: true,
  [tableSortingKeys.shipmentStatus]: false,
  [tableSortingKeys.shipmentEstimatedDeparture]: true,
  [tableSortingKeys.shipmentEstimatedArrival]: true,
  [tableSortingKeys.shipmentEstimatedDischarge]: true,
  [tableSortingKeys.shipmentLoadingPort]: true,
  [tableSortingKeys.shipmentDischargePort]: true,
  [tableSortingKeys.shipmentTerminal]: true,
  [tableSortingKeys.containerPickupStatus]: true,
  [tableSortingKeys.containerPickupTime]: true,
  [tableSortingKeys.containerDeliveryStatus]: true,
  [tableSortingKeys.containerDeliveryTime]: true,
  [tableSortingKeys.containerSealNumber]: false,
  [tableSortingKeys.shipmentTitle]: true,
  [tableSortingKeys.shipmentVessel]: true,
  [tableSortingKeys.containerDischargeAt]: true,
  [tableSortingKeys.shipmentTerminalEstimatedArrival]: true,
  [tableSortingKeys.containerGateOut]: true,
  [tableSortingKeys.containerInspectionDatetime]: true,
  [tableSortingKeys.containerPodCustomsInspectionStatus]: true,
  [tableSortingKeys.containerPodCustomsInspectionType]: true,
  [tableSortingKeys.containerActualFirstDayOfDemurrage]: true,
  [tableSortingKeys.shipmentCarrierEstimatedArrival]: true,
  [tableSortingKeys.shipmentModality]: true,
  [tableSortingKeys.shipmentLoadType]: true,
  [tableSortingKeys.shipmentConsignees]: true,
  [tableSortingKeys.shipmentShippers]: true,
  [tableSortingKeys.shipmentSharedReference]: true,
  [tableSortingKeys.houseBlNumbers]: true,
  [tableSortingKeys.masterBlNumber]: true,
  [tableSortingKeys.pickupTransporter]: true,
  [tableSortingKeys.deliveryTransporter]: true,
  [tableSortingKeys.shipmentClosingDateTime]: true,
}
