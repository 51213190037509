import {
  FunctionComponent,
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import usePreAlert from 'src/hooks/usePreAlert'
import { showNotification } from 'src/stores/actionCreators/notifications'
import {
  Tab,
  Tabs,
  Button,
  Typography,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Box,
} from '@mui/material'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import {
  find,
  toString,
  toNumber,
  each,
  orderBy,
  uniqBy,
  flatten,
} from 'lodash'
import { ModalityEnum } from 'src/config/constants'
import ShipmentDocument from 'src/components/ShipmentDocument'
import DataLoad from 'src/components/Common/DataLoad'
import ConfirmDialog from 'src/components/ConfirmDialog'
import TabPanel from 'src/components/Common/TabPanel'
import BookingPurchaseOrderLine from 'src/components/PurchaseOrders/BookingPurchaseOrderLine'
import UserChannelClient from 'src/components/SocketHandlers/UserChannelClient'
import { promisifyAction, permissionTo } from '../../../utils'
import './styles.scss'

import { MilestoneTable } from '../../../stories/MilestoneTable'
import BookingEvent from '../BookingEvent'
import BookingPartiesLayout from '../ShipmentBookingParties/BookingPartiesLayout'
import {
  createShipmentDocument,
  shipmentDocumentSubmitData,
  deleteShipmentDocument,
  shipmentsGetParties,
  shipmentDocumentsOpenItem,
  toggleShipmentShareModal,
} from '../../../stores/actionCreators'

import OrdersBlankTab from './OrdersBlankTab'
import { CreateBookingOverviewButton } from './CreateBookingOverviewButton'

interface IProps {
  booking: IBooking
  shipmentId: number | string
  openBookingModal: (booking: IBooking | null) => void
  openPartiesModal: (booking: IBooking | null, type: string) => void
  fetchData: () => void
  bookingId: number | null
  containerId: number | null
}

const ShipmentBookingBody: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const [tabValue, setTabValue] = useState<string>(
    permissionTo('shipments.bookings.documents.view') ? 'docs' : 'events'
  )
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false)
  const [filesArr, setFilesArr] = useState<File[]>([])
  const [editableDocument, setEditableDocument] = useState<IFileData[]>([])
  const [editableDocumentId, setEditableDocumentId] = useState<number | null>(
    null
  )
  const [openRemoveDocModal, setOpenRemoveDocModal] = useState<boolean>(false)
  const [currentDocument, setCurrentDocument] = useState<any>(null)
  const uploadButtonRef = useRef<any>(null)
  const dispatch = useDispatch()
  const isPreAlert = usePreAlert()

  const createShipmentDocumentAsync = promisifyAction(
    dispatch,
    createShipmentDocument
  )
  const shipmentPartiesGetDataAsync = promisifyAction(
    dispatch,
    shipmentsGetParties
  )

  const updateShipmentDocumentAsync = promisifyAction(
    dispatch,
    shipmentDocumentSubmitData
  )

  const deleteShipmentDocumentAsync = promisifyAction(
    dispatch,
    deleteShipmentDocument
  )

  const showSuccess = (message: string, duration?: number) => {
    dispatch(showNotification({ message, severity: 'success' }))
  }
  const showError = (message: string, duration?: number) => {
    dispatch(showNotification({ message, severity: 'error' }))
  }

  useEffect(() => {
    shipmentPartiesGetDataAsync(props.shipmentId)
  }, [])

  const {
    documentTypes,
    bookingsArr,
    containersArr,
    organizationId,
    parties,
    isModalityAir,
    shipment,
  } = useSelector((state: IGlobalState) => ({
    documentTypes: state.shipmentDocs.documentTypes,
    bookingsArr: state.bookings.bookings,
    containersArr: state.bookings.containers,
    organizationId: state.user.organizationId,
    parties: state.shipmentOverview.collaborators,
    shipment: state.shipmentOverview,
    isModalityAir: state.shipmentOverview.modality === ModalityEnum.Air,
  }))

  const showPublicLink: boolean =
    permissionTo('shipments.public_link.all') &&
    !!shipment &&
    !!shipment.token_enabled &&
    !!shipment.token

  useEffect(() => {
    return () => {
      dispatch(shipmentDocumentsOpenItem(null))
    }
  }, [])

  const onTabsChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue)
  }

  const documentTypesSet = useMemo(() => {
    if (!props.booking.shipment_documents?.length) {
      return documentTypes
    }

    const appliedDocumentTypes = flatten(
      props.booking.shipment_documents.map((x) => x.types)
    )

    return uniqBy([...documentTypes, ...appliedDocumentTypes], 'id')
  }, [documentTypes, props.booking.shipment_documents])

  const leadForwarder = useMemo(() => {
    return parties.find((x) => x.roles.some((x) => x.id === 17))
  }, [parties])

  const renderedDocuments = (): React.ReactNode => {
    if (props.booking.shipment_documents.length) {
      const sortedDocs = orderBy(
        props.booking.shipment_documents,
        'created_at',
        'desc'
      )
      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="s-wide">
                {t('shipments.bookings.documents_table.header.name', 'name')}
              </TableCell>
              <TableCell className="s-medium">
                {t('shipments.bookings.documents_table.header.type', 'type')}
              </TableCell>
              {!isModalityAir && (
                <TableCell className="s-medium">
                  {t(
                    'shipments.bookings.documents_table.header.container',
                    'container'
                  )}
                </TableCell>
              )}
              <TableCell className="s-medium">
                {t(
                  'shipments.bookings.documents_table.header.uploaded_at',
                  'uploaded at'
                )}
              </TableCell>
              <TableCell className="right-aligned s-medium">
                {t(
                  'shipments.bookings.documents_table.header.viewable_by',
                  'viewable by'
                )}
              </TableCell>
              <TableCell className="short"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedDocs.map((document) => (
              <ShipmentDocument
                key={document.id}
                document={document}
                shipmentId={props.shipmentId}
                onEdit={onEditDocument}
                onDelete={onDeleteDocument}
                isModalityAir={isModalityAir}
                containersCount={(containersArr || []).length}
                downloadable={permissionTo(
                  'shipments.bookings.documents.manage'
                )}
                disabled={
                  !document.document_manageable ||
                  !permissionTo('shipments.bookings.documents.manage')
                }
                showPublicLink={showPublicLink}
              />
            ))}
          </TableBody>
        </Table>
      )
    } else {
      return (
        <div className="message-title">
          <Typography
            variant="body1"
            children={t(
              'shipments.bookings.documents_table.no_documents',
              'No document available'
            )}
          />
        </div>
      )
    }
  }

  const renderedOrders = (): React.ReactNode => {
    if (props.booking.purchase_orders && props.booking.purchase_orders.length) {
      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="s-medium">
                {t(
                  'shipments.bookings.orders_table.headers.po_number',
                  'po number'
                )}
              </TableCell>
              <TableCell className="short-50"></TableCell>
              <TableCell className="s-medium">
                {t(
                  'shipments.bookings.orders_table.headers.product_name',
                  'PRODUCT NAME'
                )}
              </TableCell>
              <TableCell className="s-medium">
                {t(
                  'shipments.bookings.orders_table.headers.article_number',
                  'ARTICLE NUMBER'
                )}
              </TableCell>
              <TableCell className="s-medium">
                {t('shipments.bookings.orders_table.headers.volume', 'VOLUME')}
              </TableCell>
              <TableCell className="s-medium">
                {t('shipments.bookings.orders_table.headers.weight', 'WEIGHT')}
              </TableCell>
              <TableCell className="s-medium"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.booking.purchase_orders.map((purchaseOrder) =>
              purchaseOrder.purchase_order_lines.map((orderLine) => (
                <BookingPurchaseOrderLine
                  key={orderLine.id}
                  item={orderLine}
                  purchaseOrderNumber={purchaseOrder.purchase_order_number}
                  purchaseOrderId={purchaseOrder.id}
                />
              ))
            )}
          </TableBody>
        </Table>
      )
    } else {
      return <OrdersBlankTab />
    }
  }

  const getEvents = (): React.ReactNode => {
    if (props.booking.booking_events && props.booking.booking_events.length) {
      return (
        <div className="booking-events-container">
          {props.booking.booking_events.map((event: IBookingEvent) => (
            <BookingEvent event={event} key={event.created_at} />
          ))}
        </div>
      )
    } else {
      return (
        <div className="message-title">
          {t(
            'shipments.bookings.orders_table.no_events',
            'No events available'
          )}
        </div>
      )
    }
  }

  const onBookingModalOpen = (): void => {
    props.openBookingModal(props.booking)
  }

  const onPartiesModalOpen = (type: string): void => {
    props.openPartiesModal(props.booking, type)
  }

  const uploadModalClose = () => {
    setOpenUploadModal(false)
    setOpenRemoveDocModal(false)
    setCurrentDocument(null)
    setEditableDocument([])
    setEditableDocumentId(null)
    setFilesArr([])
  }

  const fetchAfterSave = async (openNew) => {
    uploadModalClose()
    await props.fetchData()
    if (openNew) {
      addNewDocument()
    }
  }

  const saveDocuments = async (
    documents: IFileData[],
    isEditDocument: boolean,
    openNew: boolean
  ): Promise<any> => {
    if (isEditDocument) {
      const dataToSend = {
        document_type_ids: documents[0].types,
        booking_id: documents[0].booking,
        container_ids: documents[0].containers,
        organization_ids: documents[0].viewable_by,
      }

      await updateShipmentDocumentAsync(
        toNumber(props.shipmentId),
        editableDocumentId,
        dataToSend
      )
      fetchAfterSave(openNew)
    } else {
      each(documents, (document: IFileData) => {
        if (!document.file) {
          return
        }
        const reader = new FileReader()
        reader.readAsDataURL(document.file)
        reader.onload = async () => {
          try {
            await createShipmentDocumentAsync(props.shipmentId, {
              file: document.file,
              document_type_ids: document.types,
              booking_id: document.booking,
              container_ids: document.containers,
              organization_ids: document.viewable_by,
            })
            showSuccess('New document uploaded')
          } catch (error) {
            const unknownError: any = error
            if (unknownError.response && unknownError.response.data) {
              showError(unknownError.response.data.message)
            }
          }
          fetchAfterSave(openNew)
        }
      })
    }
  }

  const addNewDocument = () => {
    uploadButtonRef?.current?.click()
  }

  const onChangeUploadFile = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const filesObj: FileList | null = event.target.files

    if (!filesObj) {
      return
    }

    if (filesObj != null && filesObj.length > 0) {
      const files: File[] = [] || filesArr
      let i: number = 0
      while (i < filesObj.length) {
        files.push(filesObj[i])
        i++
      }
      setFilesArr(files)
      setOpenUploadModal(true)
    }

    uploadButtonRef.current.value = null
  }

  const onEditDocument = (id: number): void => {
    const doc: any = find(props.booking.shipment_documents, { id: id }) || null
    if (doc) {
      setEditableDocumentId(doc.id)
      setEditableDocument([
        {
          name: doc.original_filename,
          types: doc.types.map((x) => `${x.id}`),
          containers: doc.containers.map((x) => `${x.id}`),
          booking: doc.booking ? toString(props.booking.id) : '',
          viewable_by: doc.viewable_by.map((x) => `${x.id}`),
        },
      ])
      setOpenUploadModal(true)
    }
  }

  const onDeleteDocument = (id: number): void => {
    const doc: any = find(props.booking.shipment_documents, { id: id }) || null
    setCurrentDocument(doc)
    setOpenRemoveDocModal(true)
  }

  const confirmDeleteDocument = async (): Promise<any> => {
    if (currentDocument) {
      try {
        await deleteShipmentDocumentAsync(props.shipmentId, currentDocument.id)
      } catch (error) {
        dispatch(
          showNotification({
            message: "Document can't be deleted.",
            severity: 'error',
          })
        )
      }
    }
    await props.fetchData()
    uploadModalClose()
  }

  const getFileData = (): IFileData[] => {
    const viewable_by = [`${organizationId}`]
    if (leadForwarder) {
      viewable_by.push(`${leadForwarder.organization_id}`)
    }
    return (filesArr || []).map((sub: File) => {
      return {
        file: sub,
        name: sub.name,
        types: [],
        containers: [],
        booking: '',
        viewable_by,
      }
    })
  }

  const tableLabel = (label: string, icon: string): React.ReactNode => {
    return (
      <div className="iconed-label">
        <span>
          <i className={`icon ${icon}`} />
        </span>
        {label}
      </div>
    )
  }

  const onReceived = (data: ISocketMessage): void => {
    if (data.message_type === 'task_resolved') {
      props.fetchData()
    }
  }

  const onClick = useCallback((task: IMilestoneTask) => {
    switch (task.task_type.code) {
      case 'add_shipper_address':
        onPartiesModalOpen('shipper')
        break
      case 'add_consignee_address':
        onPartiesModalOpen('consignee')
        break
      case 'provide_cargo_ready_date':
        onBookingModalOpen()
        break
      case 'add_notify_party_address':
        onPartiesModalOpen(
          props.booking.consignee_as_notify_party ? 'consignee' : 'notify_party'
        )
        break
      case 'upload_commercial_invoice': // same as upload_packing_list
      case 'upload_packing_list':
      default:
        addNewDocument()
        break
    }
  }, [])

  const openShareModal = () => dispatch(toggleShipmentShareModal(true, null))

  return (
    <div className="container-overview-expanded">
      {props.booking.milestones && !!props.booking.milestones.length && (
        <Box px={2}>
          <MilestoneTable
            milestones={props.booking.milestones}
            onClick={onClick}
            resolvedTasksMessage={t(
              'shipments.bookings.resolved_tasks_message',
              'You have no open tasks for this booking'
            )}
          />
        </Box>
      )}
      <div className="shipping-bookings--body">
        <UserChannelClient onReceived={onReceived} />

        {isPreAlert && (
          <Button
            variant="outlined"
            size="large"
            className="shipping-bookings--alert"
            onClick={openShareModal}
          >
            {t('shipments.bookings.send_pre_alert', 'Send pre-alert')}
          </Button>
        )}

        {permissionTo('shipments.bookings.manage') && (
          <Button
            variant="outlined"
            size="large"
            className="shipping-bookings--edit"
            onClick={onBookingModalOpen}
            startIcon={<CreateRoundedIcon />}
            data-testid="edit-booking-trigger"
          >
            {t('shipments.bookings.edit_booking', 'Edit booking')}
          </Button>
        )}

        <div className="shipping-bookings--title">
          <Typography
            variant="h5"
            children={t('shipments.bookings.parties', 'Parties')}
          />
        </div>
        <div className="shipping-bookings--body-block parties">
          <BookingPartiesLayout
            shipmentId={props.shipmentId || 0}
            theme="bookings-page"
            booking={props.booking}
            openBookingModal={onBookingModalOpen}
            openPartiesModal={onPartiesModalOpen}
            fetchData={props.fetchData}
          />
        </div>
        <div className="shipping-bookings--body-block docs">
          <Tabs
            value={tabValue}
            onChange={onTabsChange}
            classes={{
              root: 'shipment-layout__tab-panel docs-panel mui-override',
            }}
          >
            {permissionTo('shipments.bookings.documents.view') && (
              <Tab
                label={tableLabel(
                  t('shipments.bookings.documents', 'Documents'),
                  'paperclip'
                )}
                value="docs"
              />
            )}
            {permissionTo('shipment.bookings.purchase_orders.view') && (
              <Tab
                label={tableLabel(
                  t(
                    'purchase_orders.shipment.bookings.tab.title',
                    'Order management'
                  ),
                  'orders'
                )}
                value="orders"
              />
            )}
            <Tab
              label={tableLabel(
                t('shipments.bookings.activity_log', 'Activity log'),
                'events'
              )}
              value="events"
            />
          </Tabs>
          <TabPanel
            value={tabValue}
            index="docs"
            className="shipping-bookings--tabpanel"
          >
            {permissionTo('shipments.bookings.documents.view') &&
              renderedDocuments()}
            {permissionTo('shipments.bookings.documents.manage') && (
              <Box mt={2}>
                <Button
                  variant="contained"
                  onClick={addNewDocument}
                  disabled={
                    !permissionTo('shipments.bookings.documents.manage')
                  }
                  className="mr-16 ml-16 mb-8"
                >
                  {t('shipments.bookings.add_documents', 'Add documents')}
                </Button>
                <CreateBookingOverviewButton
                  shipmentId={String(props.shipmentId)}
                />
              </Box>
            )}
          </TabPanel>
          <TabPanel
            value={tabValue}
            index="orders"
            className="shipping-bookings--tabpanel"
          >
            {permissionTo('shipment.bookings.purchase_orders.view') &&
              renderedOrders()}
          </TabPanel>
          <TabPanel
            value={tabValue}
            index="events"
            className="shipping-bookings--tabpanel"
          >
            {getEvents()}
          </TabPanel>
        </div>
      </div>
      <input
        ref={uploadButtonRef}
        type="file"
        id="selectedFile"
        style={{
          display: 'none',
        }}
        onChange={onChangeUploadFile}
        multiple={true}
        data-testid="add-shipment-documents-file-input"
      />
      <DataLoad.UploadFiles
        shipment={true}
        title="documents"
        isEditDocument={!!editableDocument.length}
        fileData={editableDocument.length ? editableDocument : getFileData()}
        isOpen={openUploadModal}
        documentTypes={documentTypesSet}
        containers={containersArr || []}
        bookings={bookingsArr || []}
        onClose={uploadModalClose}
        submitUpload={saveDocuments}
        bookingId={props.bookingId}
        containerId={props.containerId}
        parties={parties}
      />
      <ConfirmDialog
        title={t('shipments.bookings.delete_documents', 'Delete document')}
        message={t(
          'shipments.bookings.delete_documents_confirmation',
          'Do you really want to delete this document?'
        )}
        isOpen={openRemoveDocModal}
        confirm={confirmDeleteDocument}
        reject={uploadModalClose}
        onClose={uploadModalClose}
      />
    </div>
  )
}

export default ShipmentBookingBody
