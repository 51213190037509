import flatMap from 'lodash/flatMap'
import omit from 'lodash/omit'
import isArray from 'lodash/isArray'
import { DateTime } from 'luxon'
import {
  fromISOwithZone,
  DATE_STRING_FORMAT,
} from 'src/utils/helpers/shipmentDate'
import { queryClient } from 'src/services/http-common'
import { queryKeys } from 'src/services/endpoints'
import { ISavedFilter } from 'src/@types/endpoints/other'
import Input from 'src/components/Overview/Search/FormElements/Input'
import ManageSearch from '@mui/icons-material/ManageSearch'
import { TFunction } from 'i18next'
import { TableSortProps } from 'src/components/Common/Table/DataTable/TableWrapper.props'
import {
  OverviewResponseRecord,
  OverviewResponse,
} from 'src/services/Api/overview/types'
import { overviewPageName } from 'src/pages/Overview/constants'
import AutocompleteAsync from 'src/components/Overview/Search/FormElements/AutocompleteAsync'
import BookingPartiesAutocompleteAsync from 'src/components/Overview/Search/FormElements/BookingPartiesAutocompleteAsync'
import DatePicker from 'src/components/Overview/Search/FormElements/DatePicker'
import AutocompleteMulti from 'src/components/Overview/Search/FormElements/AutocompleteMulti'
import ShipmentStatusesAutocomplete from 'src/components/Overview/Search/FormElements/ShipmentStatusesAutocomplete'
import {
  getLocalStorage,
  getTableStorage,
} from 'src/components/Common/Table/DataTable/TableWrapper.utils'
import { getRequestFilterParams } from 'src/components/Overview/ContentTableColumns'
import { searchParams, customLabelsForWeekOptions } from './Search/constants'
import { savedViewLocalStorageKey, tableStorageKey } from './constants'
import { DateSelectorOptionProps, FilterOptions } from './Search/types'

export const getSearchToken = (input: string) => {
  return {
    value: 'search',
    icon: ManageSearch,
    input,
    label: `Press enter ⏎ to search for "${input}"`,
    component: Input,
    group: 'filters',
  } as FilterOptions
}

export const getOrderParams = (order: TableSortProps[] | undefined) => {
  return (
    order?.map((sort: TableSortProps) => {
      return `${sort.id}:${sort.desc ? 'desc' : 'asc'}`
    }) ?? []
  )
}

export const getDisplayDate = (date: string, omitTime = false) => {
  if (!date) {
    return '-'
  }
  const zone = DateTime.local().zoneName
  const luxonDate = fromISOwithZone(date).setZone(zone)
  if (Math.abs(luxonDate.diffNow('months').months) >= 1 || omitTime) {
    return luxonDate.toFormat('dd MMM yyyy')
  } else {
    return luxonDate.toFormat('dd MMM HH:mm')
  }
}

export const flattenObject = (input) => {
  let result = {}
  for (const key in input) {
    if (!input.hasOwnProperty(key)) {
      continue
    }
    if (typeof input[key] === 'object' && !Array.isArray(input[key])) {
      let subFlatObject = flattenObject(input[key])
      for (const subkey in subFlatObject) {
        result[key + '_' + subkey] = subFlatObject[subkey]
      }
    } else {
      result[key] = input[key]
    }
  }
  return result
}

export const reorder = (
  list: Iterable<unknown> | ArrayLike<unknown>,
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const convertDateToUnixTimestamp = (date: string, endOfDay = false) => {
  const dateToConvert: DateTime = endOfDay
    ? DateTime.fromISO(date).endOf('day')
    : DateTime.fromISO(date).startOf('day')

  return dateToConvert.toUTC().toSeconds()
}

export const convertDateFromUnixTimestamp = (
  date: number,
  format = DATE_STRING_FORMAT
) => {
  return DateTime.fromSeconds(date).toFormat(format)
}

export const convertDatePairToUnixTimestampRange = (
  start: DateTime,
  end: DateTime
): number[] => {
  return [
    start.startOf('day').toUTC().toSeconds(),
    end.endOf('day').toUTC().toSeconds(),
  ]
}

export const getDateOptionRange = (option: DateSelectorOptionProps) => {
  return option.diff()
}

const getDateOptionForSaturdayToFriday = (
  currentWeekNumber: number,
  t: TFunction<'translation', undefined>
): DateSelectorOptionProps => {
  const dt = DateTime.fromObject({
    weekNumber: currentWeekNumber,
  })

  const nextSaturday = dt.endOf('week').minus({ days: 1 })

  const nextFriday = nextSaturday.plus({ days: 6 })

  return {
    label: t('vessels_page.filters.date_picker.next_saturday_to_friday', {
      defaultValue: 'Next Sat - Fri',
    }),
    value: 'sat-fri',
    diff: () => {
      return convertDatePairToUnixTimestampRange(nextSaturday, nextFriday)
    },
  }
}

export const getDateOptionRangeForWeek = (
  t: TFunction<'translation', undefined>
) => {
  const options: DateSelectorOptionProps[] = []
  const now = DateTime.now()
  const currentWeekNumber = Number(now.toFormat('WW'))

  options.push(getDateOptionForSaturdayToFriday(currentWeekNumber, t))

  for (let i = 0; i < 3; i++) {
    const label = customLabelsForWeekOptions[i]
    const week = currentWeekNumber + i
    const dt = DateTime.fromObject({
      weekNumber: week,
    })

    const start = dt.startOf('week')

    const end = dt.endOf('week')

    options.push({
      label: t(`vessels_page.filters.date_picker.${label.i18nKey}`, {
        defaultValue: label.defaultValue,
        weekNumber: week,
      }),
      value: `week-${week}`,
      diff: () => {
        return convertDatePairToUnixTimestampRange(start, end)
      },
    })
  }
  return options
}

export const getFlattenTableData = (records: OverviewResponseRecord[]) => {
  return flatMap(records, (record) => {
    if (
      record.type === 'vessel' &&
      record.record_type === 'group' &&
      record.data.records.length === 0
    ) {
      return [
        {
          shipment_vessel_name: record.title,
          shipment_vessel_id: record.id,
          ...record?.metadata,
        },
      ]
    }
    return record.data.records.map((subRecord) => {
      return {
        ...subRecord,
        ...record?.metadata,
      }
    })
  })
}

export const getPreparedTableData = (records: OverviewResponseRecord[]) => {
  const firstLevelRecords = getFlattenTableData(records)

  return firstLevelRecords.map((record) => {
    return flattenObject(record)
  })
}

export const getExpandedRowData = (
  res: OverviewResponse,
  id: number,
  tableRecords: OverviewResponseRecord[]
) => {
  return (
    tableRecords.map((record) => {
      if (record.id === id) {
        return {
          ...record,
          data: {
            ...record.data,
            records: [
              ...record.data.records,
              ...flatMap(res?.data?.records, (record) => record.data.records),
            ].map((recordItem, index) => {
              return { ...recordItem, leafIndex: index }
            }),
          },
        }
      }
      return record
    }) ?? []
  )
}

export const isInViewport = (element, container: HTMLElement | null) => {
  if (!container) {
    return false
  }
  const eleTop = element.offsetTop
  const eleBottom = eleTop + element.clientHeight

  const containerTop = container.scrollTop
  const containerBottom = containerTop + container.clientHeight

  // The element is fully visible in the container
  return (
    (eleTop >= containerTop && eleBottom <= containerBottom) ||
    // Some part of the element is visible in the container
    (eleTop < containerTop && containerTop < eleBottom) ||
    (eleTop < containerBottom && containerBottom < eleBottom)
  )
}

export const getSearchOptions = (
  t: TFunction<'translation', undefined>
): FilterOptions[] => {
  return [
    {
      value: searchParams.pol,
      shortLabel: 'POL',
      label: t('vessels_page.data_table.filters.pol', 'Port of loading (POL)'),
      component: AutocompleteAsync,
      group: 'filters',
    },
    {
      value: searchParams.pod,
      shortLabel: 'POD',
      label: t(
        'vessels_page.data_table.filters.pod',
        'Port of discharge (POD)'
      ),
      component: AutocompleteAsync,
      group: 'filters',
    },
    {
      value: searchParams.consignee,
      shortLabel: 'Consignee',
      label: t('vessels_page.data_table.filters.consignee', 'Consignee'),
      component: BookingPartiesAutocompleteAsync,
      group: 'filters',
    },
    {
      value: searchParams.shipper,
      shortLabel: 'Shipper',
      label: t('vessels_page.data_table.filters.shipper', 'Shipper'),
      component: BookingPartiesAutocompleteAsync,
      group: 'filters',
    },
    {
      value: searchParams.departure,
      shortLabel: 'ETD',
      label: t('vessels_page.data_table.filters.departure', 'Departure'),
      component: DatePicker,
      group: 'filters',
    },
    {
      value: searchParams.arrival,
      shortLabel: 'ETA',
      label: t('vessels_page.data_table.filters.arrival', 'Arrival'),
      component: DatePicker,
      group: 'filters',
    },
    {
      value: searchParams.pickupTime,
      shortLabel: 'Pickup time',
      label: t('vessels_page.data_table.filters.pickup_time', 'Pickup date'),
      component: DatePicker,
      group: 'filters',
    },
    {
      value: searchParams.deliveryTime,
      shortLabel: 'Delivery date',
      label: t(
        'vessels_page.data_table.filters.delivery_time',
        'Delivery date'
      ),
      component: DatePicker,
      group: 'filters',
    },
    {
      value: searchParams.modality,
      label: t('vessels_page.data_table.filters.modality', 'Modality'),
      component: AutocompleteMulti,
      group: 'filters',
    },
    {
      value: searchParams.loadType,
      label: t('vessels_page.data_table.filters.load', 'Load type'),
      component: AutocompleteMulti,
      group: 'filters',
    },
    {
      value: searchParams.shipmentStatus,
      label: t(
        'vessels_page.data_table.filters.shipment_status',
        'Shipment status'
      ),
      component: ShipmentStatusesAutocomplete,
      group: 'filters',
    },
    {
      value: searchParams.containerScan,
      label: t(
        'vessels_page.data_table.filters.container_scan_status',
        'Container scan status'
      ),
      component: AutocompleteMulti,
      group: 'filters',
    },
    {
      value: searchParams.pickupStatus,
      label: t(
        'vessels_page.data_table.filters.pickup_status',
        'Pick-up status'
      ),
      component: AutocompleteMulti,
      group: 'filters',
    },
    {
      value: searchParams.deliveryStatus,
      label: t(
        'vessels_page.data_table.filters.delivery_status',
        'Delivery status'
      ),
      component: AutocompleteMulti,
      group: 'filters',
    },
  ]
}

export const handleUpdateViewSuccess = (
  res: ShipmentQuickFilter,
  savedViewId: number
) => {
  queryClient.setQueriesData([queryKeys.savedFilters], (oldViews: any) => {
    if (!oldViews) return []
    return oldViews.map((view: ISavedFilter) => {
      if (view.id === savedViewId) {
        return res
      }
      return view
    })
  })
}

export const getNewView = (name: string, filter) => {
  const {
    storedDensity: density,
    storedColumnOrder: columnOrder,
    storedColumnVisibility: columnVisibility,
    storedColumnSizing: columnSizing,
    storedColumnPinning: columnPinning,
    storedColumnSorting: columnSorting,
  } = getTableStorage(tableStorageKey)

  const table = {
    density,
    columnOrder,
    columnPinning,
    columnSorting,
    columnSizing,
    columnVisibility,
  }

  return {
    name,
    table,
    page: overviewPageName,
    filter: getRequestFilterParams(omit(filter, 'open', 'page')),
  }
}

export const getLocallyStoredView = () => {
  const savedViewId = getLocalStorage(savedViewLocalStorageKey, null)
  const views = queryClient.getQueryData([queryKeys.savedFilters]) ?? []
  return isArray(views) ? views?.find((view) => view.id === savedViewId) : null
}
