import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import StarOutlinedIcon from '@mui/icons-material/StarOutlined'
import { Button, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { Add } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

const OverviewViewsList = ({
  views,
  savedView,
  onItemClick,
  onSaveClick,
  onRenameClick,
  onDeleteClick,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          maxHeight: 'calc(100vh - 140px)',
          '& ul': { padding: 0 },
          '& ul li': { pl: 1.5, pr: 1 },
        }}
        subheader={<li />}
      >
        {savedView && (
          <>
            <ListItemButton onClick={onRenameClick}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText
                primary={t('overview_views.actions.edit', 'Rename')}
              />
            </ListItemButton>
            <ListItemButton onClick={onDeleteClick}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText
                primary={t('overview_views.actions.delete', 'Delete')}
              />
            </ListItemButton>
          </>
        )}
        <li>
          <ul>
            <ListSubheader sx={{ my: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="overline" color="text.secondary">
                  {t('vessels_page.views.my_views', 'My views')}
                </Typography>
                <Button size="small" onClick={onSaveClick}>
                  <Add /> {t('vessels_page.views.add_view', 'Add new')}
                </Button>
              </Stack>
            </ListSubheader>
            {views.map((view) => (
              <ListItemButton
                key={`item-${view.id}`}
                onClick={() => onItemClick(view)}
              >
                <ListItemIcon>
                  <StarOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography noWrap children={view.name} />}
                />
              </ListItemButton>
            ))}
          </ul>
        </li>
      </List>
    </>
  )
}

export default OverviewViewsList
