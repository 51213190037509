import { FunctionComponent, useMemo, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { History, LocationState } from 'history'
import Typography from '@mui/material/Typography'
import { toNumber, find, sortBy, each } from 'lodash'
import Dropzone from 'react-dropzone'
import { Button } from '@mui/material'
import DataLoad from 'src/components/Common/DataLoad'
import LoadableContainer from 'src/components/LoadableContainer'
import ContentDropDown from 'src/components/Common/ContentDropDown'
import { useTranslation } from 'react-i18next'
import { promisifyAction, permissionTo } from '../../utils'
import 'src/components/PickupAndDelivery/ContainerOverviewHeaderCollapsed/styles.scss'
import './styles.scss'

import BookingPartiesModal from '../../components/Booking/ShipmentBookingParties/BookingPartiesModal'
import {
  bookingsGetData,
  addressesGetData,
  personalDetailsGetData,
  clearBookingsState,
  documentTypesGetData,
  createShipmentDocument,
  shipmentsGetParties,
} from '../../stores/actionCreators'

import ShipmentBookingHeader from '../../components/Booking/ShipmentBookingHeader'
import ShipmentBookingBody from '../../components/Booking/ShipmentBookingBody'
import BookingEditModal from '../../components/Booking/BookingEditModal'
import CarrierBookings from './CarrierBookings'

interface IProps {
  match: IMatch | null
  history: History<LocationState>
}

const ShipmentBookings: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const [currentBookingId, setCurrentBookingId] = useState<number | null>(null)
  const [currentBooking, setCurrentBooking] = useState<IBooking | null>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openPartiesInfoModal, setOpenPartiesInfoModal] = useState<boolean>(
    false
  )
  const [partiesModalType, setPartiesModalType] = useState<string>('')
  const [isDropzoneActive, setIsDropzoneActive] = useState<boolean>(false)
  const [filesArr, setFilesArr] = useState<File[]>([])
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false)
  const match = props.match || { params: { id: 0 } }
  const bookingIdFromURL: string = window.location.hash.replace('#', '')
  const dispatch = useDispatch()
  const uploadButtonRef = useRef<any>(null)

  const getBookingsAsync = promisifyAction(dispatch, bookingsGetData)
  const shipmentPartiesGetDataAsync = promisifyAction(
    dispatch,
    shipmentsGetParties
  )
  const addressesGetDataAsync = promisifyAction(dispatch, addressesGetData)
  const personalDetailsGetDataAsync = promisifyAction(
    dispatch,
    personalDetailsGetData
  )
  const documentTypesGetDataAsync = promisifyAction(
    dispatch,
    documentTypesGetData
  )

  const permission: boolean =
    permissionTo('shipments.bookings.parties.manage') &&
    permissionTo('address_book.manage')
  const addressBookPermission = permissionTo(
    'shipments.bookings.parties.manage'
  )
  const {
    shipmentModality,
    bookingsArr,
    actionsPermission,
    bookingsDataReceived,
    containersArr,
    documentTypes,
    parties,
    organizationId,
    ownerOrganizationId,
  } = useSelector((state: IGlobalState) => ({
    bookingsArr: state.bookings.bookings,
    containersArr: state.bookings.containers,
    shipmentModality: state.shipmentOverview.modality,
    actionsPermission: state.shipmentOverview.add_and_remove_bookings,
    ownerOrganizationId: state.shipmentOverview.owner_organization_id,
    bookingsDataReceived: state.bookings.bookingsDataReceived,
    documentTypes: state.shipmentDocs.documentTypes,
    parties: state.shipmentOverview.collaborators,
    organizationId: state.user.organizationId,
  }))

  const isSingleBooking: boolean = !!bookingsArr && bookingsArr.length === 1

  useEffect(() => {
    Promise.all([
      getBookingsAsync(match.params.id),
      shipmentPartiesGetDataAsync(match.params.id),
      documentTypesGetDataAsync(shipmentModality),
    ])
    if (bookingIdFromURL) {
      setCurrentBookingId(toNumber(bookingIdFromURL))
    }
    return () => {
      dispatch(clearBookingsState())
    }
  }, [match.params.id])

  useEffect(() => {
    Promise.all([
      addressesGetDataAsync({
        organization_id: ownerOrganizationId,
        shipment_id: match.params.id,
      }),
      personalDetailsGetDataAsync({ organization_id: ownerOrganizationId }),
    ])
  }, [ownerOrganizationId, match.params.id])

  const createShipmentDocumentAsync = promisifyAction(
    dispatch,
    createShipmentDocument
  )

  const leadForwarder = useMemo(() => {
    return parties.find((x) => x.roles.some((x) => x.id === 17))
  }, [parties])

  const setBookingId = (id: number | null): void => {
    setCurrentBookingId(id)
    props.history.push(id ? `#${id}` : `/shipments/${match.params.id}/bookings`)
  }

  const getCurrentBooking = (): IBooking | null => {
    if (currentBookingId && bookingsArr) {
      const currentBooking: IShipmentContainer | {} =
        find(bookingsArr, { id: currentBookingId }) || {}
      return currentBooking as IBooking
    }
    if (isSingleBooking) {
      return bookingsArr ? (bookingsArr[0] as IBooking) : null
    }
    return null
  }

  const openBookingModal = (booking: IBooking | null): void => {
    setOpenModal(true)
    setCurrentBooking(booking)
    setBookingId(booking ? booking.id : null)
  }

  const openPartiesModal = (booking: IBooking | null, type: string): void => {
    setOpenPartiesInfoModal(true)
    setPartiesModalType(type)
    setCurrentBooking(getCurrentBooking() || booking)
    setBookingId(booking ? booking.id : null)
  }

  const closeModal = (): void => {
    setOpenModal(false)
    setPartiesModalType('')
    setOpenPartiesInfoModal(false)
    setCurrentBooking(null)
    fetchData()
  }

  const addNewBooking = () => {
    setOpenModal(true)
    setCurrentBooking(null)
  }

  const fetchData = (): void => {
    Promise.all([
      getBookingsAsync(match.params.id),
      addressesGetDataAsync({ organization_id: ownerOrganizationId }),
      personalDetailsGetDataAsync({ organization_id: ownerOrganizationId }),
    ])
  }

  const collapseItems = () => {
    setCurrentBookingId(null)
    props.history.push(`/shipments/${match.params.id}/bookings`)
  }

  const sortedBookingsArray = (): IBooking[] => {
    return sortBy(bookingsArr, 'id')
  }

  const onDragEnter = () => {
    setIsDropzoneActive(true)
  }

  const onDragLeave = () => {
    setIsDropzoneActive(false)
  }

  const onDrop = (files: File[]): void => {
    if (files != null && files.length > 0) {
      setFilesArr(files)
      setOpenUploadModal(true)
    }
    setIsDropzoneActive(false)
  }

  const uploadModalClose = () => {
    setOpenUploadModal(false)
    setFilesArr([])
  }

  const fileData = useMemo(() => {
    const viewable_by = [`${organizationId}`]
    if (leadForwarder) {
      viewable_by.push(`${leadForwarder.organization_id}`)
    }
    return (filesArr || []).map((sub: File) => {
      return {
        file: sub,
        name: sub.name,
        types: [],
        containers: [],
        booking: '',
        viewable_by,
      }
    })
  }, [filesArr, organizationId, leadForwarder])

  const addNewDocument = () => {
    uploadButtonRef.current.click()
  }

  const saveDocuments = async (
    documents: IFileData[],
    isEditDocument: boolean,
    openNew: boolean
  ): Promise<any> => {
    each(documents, (document: IFileData) => {
      if (!document.file) {
        return
      }
      const reader = new FileReader()
      reader.readAsDataURL(document.file)
      reader.onload = async () => {
        await createShipmentDocumentAsync(match.params.id, {
          file: document.file,
          document_type_ids: document.types,
          booking_id: document.booking,
          container_ids: document.containers,
          organization_ids: document.viewable_by,
        })
        uploadModalClose()
        await fetchData()
      }
    })
    if (openNew) {
      addNewDocument()
    }
  }

  const onChangeUploadFile = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const filesObj: FileList | null = event.target.files

    if (!filesObj) {
      return
    }

    if (filesObj != null && filesObj.length > 0) {
      const files: File[] = [] || filesArr
      let i: number = 0
      while (i < filesObj.length) {
        files.push(filesObj[i])
        i++
      }
      setFilesArr(files)
      setOpenUploadModal(true)
    }

    uploadButtonRef.current.value = null
  }

  const getContainerOrBookingId = (type: string): number | null => {
    switch (type) {
      case 'container':
        if (containersArr && containersArr.length === 1) {
          return containersArr[0] ? containersArr[0].id : null
        }
        return null
      case 'booking':
        if (bookingsArr && bookingsArr.length === 1) {
          return bookingsArr[0] ? bookingsArr[0].id : null
        }
        return null
      default:
        return null
    }
  }

  return (
    <div className="shipping-bookings pickup-and-delivery">
      <LoadableContainer
        className="shipping-bookings__global-loader"
        loading={!bookingsDataReceived}
      >
        <Dropzone
          onDrop={onDrop}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          multiple={true}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()} className="relative">
                {isDropzoneActive && (
                  <div className="shipment-docs-page__documents-overlay">
                    <i className="icon folder" />
                    <div className="shipment-docs-page__documents-overlay--title">
                      {t(
                        'shipment_documents.dropzone.release_your_documents',
                        'Release your documents'
                      )}
                    </div>
                    <div className="shipment-docs-page__documents-overlay--text">
                      {t(
                        'shipment_documents.dropzone.add_files',
                        'Add files by dropping them in this window'
                      )}
                    </div>
                  </div>
                )}

                {permissionTo('carrier_bookings.view') && (
                  <CarrierBookings history={props.history} />
                )}

                {(sortedBookingsArray() || []).map((booking, index) => {
                  return (
                    <ContentDropDown
                      key={booking.id}
                      className="container-overview-block"
                      itemId={booking.id}
                      setItemIdSelected={setBookingId}
                      header={
                        <ShipmentBookingHeader
                          booking={booking}
                          collapsed={false}
                          shipmentModality={shipmentModality}
                          openBookingModal={openBookingModal}
                          openPartiesModal={openPartiesModal}
                        />
                      }
                      headerCollapsed={
                        <ShipmentBookingHeader
                          booking={booking}
                          collapsed={true}
                          shipmentModality={shipmentModality}
                          openBookingModal={openBookingModal}
                          openPartiesModal={openPartiesModal}
                        />
                      }
                      body={
                        <ShipmentBookingBody
                          booking={booking}
                          shipmentId={match.params.id}
                          openBookingModal={openBookingModal}
                          openPartiesModal={openPartiesModal}
                          fetchData={fetchData}
                          bookingId={getContainerOrBookingId('booking')}
                          containerId={getContainerOrBookingId('container')}
                        />
                      }
                      forcedOpen={
                        isSingleBooking || booking.id === currentBookingId
                      }
                      disableCollapse={isSingleBooking}
                    />
                  )
                })}
                {permissionTo('shipments.bookings.manage') && (
                  <div className="shipping-bookings--new-booking">
                    <div className="shipping-bookings--new-booking-info">
                      <Typography
                        variant="body1"
                        children={t(
                          'shipments.bookings.add_booking_description',
                          'Add booking to create a consolidated shipment with multiple shippers'
                        )}
                      />
                    </div>
                    <Button
                      variant="outlined"
                      onClick={addNewBooking}
                      disabled={!actionsPermission}
                      data-testid="add-booking-button"
                    >
                      {t('shipments.bookings.add_booking', 'Add booking')}
                    </Button>
                  </div>
                )}
                <input
                  {...getInputProps()}
                  ref={uploadButtonRef}
                  type="file"
                  id="selectedFile"
                  style={{
                    display: 'none',
                  }}
                  onChange={onChangeUploadFile}
                  multiple={true}
                />
                <BookingEditModal
                  shipmentModality={shipmentModality}
                  booking={currentBooking}
                  open={openModal}
                  close={closeModal}
                  fetchData={fetchData}
                  shipment_id={match.params.id}
                  collapseItems={collapseItems}
                />
                <BookingPartiesModal
                  fetchData={fetchData}
                  open={openPartiesInfoModal}
                  handleClose={closeModal}
                  type={partiesModalType}
                  permission={permission}
                  disableTextInputs={!permissionTo('address_book.manage')}
                  addressBookPermission={addressBookPermission}
                  shipmentId={parseInt(match.params.id as string)}
                  bookingId={(currentBooking && currentBooking.id) || 0}
                  booking={getCurrentBooking() || ({} as IBooking)}
                />
                <DataLoad.UploadFiles
                  shipment={true}
                  title="documents"
                  isEditDocument={false}
                  fileData={fileData}
                  isOpen={openUploadModal}
                  documentTypes={documentTypes}
                  containers={containersArr || []}
                  bookings={bookingsArr || []}
                  onClose={uploadModalClose}
                  submitUpload={saveDocuments}
                  parties={parties}
                  bookingId={getContainerOrBookingId('booking')}
                  containerId={getContainerOrBookingId('container')}
                />
              </div>
            </section>
          )}
        </Dropzone>
      </LoadableContainer>
    </div>
  )
}

export default ShipmentBookings
