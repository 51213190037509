import { FunctionComponent, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  Stack,
  Box,
  Button,
  CircularProgress,
  Typography,
  IconButton,
} from '@mui/material'
import {
  toNumber,
  toString,
  without,
  includes,
  orderBy,
  cloneDeep,
} from 'lodash'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@mui/icons-material/Delete'
import { ModalityEnum } from 'src/config/constants'
import useDeepCompareEffect from 'src/hooks/useDeepCompareEffect'
import Modal from 'src/components/Common/Modal'
import { SingleSelect } from 'src/stories/Lab/Select/SingleSelect'
import { MultiSelect } from 'src/stories/Lab/Select/MultiSelect'
import AutocompleteCommonRecentOptionsWrapper from 'src/stories/Lab/Autocomplete/AutocompleteCommonRecentOptionsWrapper'
import { permissionTo } from '../../../../utils'
import './styles.scss'

interface IProps {
  shipment: boolean
  className?: string
  title?: string
  isOpen: boolean
  theme?: string
  fileData: IFileData[]
  onClose: () => void
  containers: any
  bookings: any
  documentTypes: IDocType[]
  submitUpload: (data: IFileData[], status: boolean, openNew: boolean) => void
  removeFile?: (index: number, fileData: IFileData[]) => void
  bookingId?: number | null
  isEditDocument: boolean
  containerId?: number | null
  parties: IShipmentParty[] | IOrganization[] | IDocumentViewableBy[]
  busyData?: boolean
}

const UploadFiles: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const [fileData, setFileData] = useState<IFileData[]>([])
  useDeepCompareEffect(() => {
    setFileData(cloneDeep(props.fileData))
  }, [props.fileData])

  const { organizationId, shipmentModality } = useSelector(
    (state: IGlobalState) => ({
      organizationId: state.user.organizationId,
      shipmentModality: state.shipmentOverview.modality,
    })
  )

  const submit = (): void => {
    props.submitUpload(fileData, props.isEditDocument, false)
  }

  const submitAndAdd = (): void => {
    if (!checkDisabled()) {
      props.submitUpload(fileData, props.isEditDocument, true)
    }
  }

  const removeFile = (index: number) => {
    const data: IFileData[] = [...fileData]
    data.splice(index, 1)
    setFileData(data)
    if (props.removeFile) {
      props.removeFile(index, fileData)
    }
    if (!data.length) {
      props.onClose()
    }
  }

  const clearAllTypes = (index) => {
    const newFileData: IFileData[] = [...fileData]
    newFileData[index].containers = []
    newFileData[index].booking = ''
    newFileData[index].types = []
    setFileData(newFileData)
  }

  const clearViewable = (index, selectedTypes) => {
    const newFileData: IFileData[] = [...fileData]
    newFileData[index].viewable_by = disabledOptionsForViewable(selectedTypes)
    setFileData(newFileData)
  }

  const handleSelectTypeChange = (selectedTypes, index) => {
    const newFileData: IFileData[] = [...fileData]

    const newArrayOfTypes = newFileData[index].types || []

    newFileData[index].types = selectedTypes.map(
      (selectedType) => `${selectedType.id}`
    )

    const docTypes = props.documentTypes.filter((x) => {
      return includes(newFileData[index].types, `${x.id}`)
    })

    const bookingLvl: boolean = !!docTypes.filter((x) => x.booking_level).length
    const containerLvl: boolean = !!docTypes.filter((x) => x.container_level)
      .length

    if (!containerLvl) {
      newFileData[index].containers = []
    } else if (!newFileData[index].containers.length) {
      newFileData[index].containers = props.containerId
        ? [`${props.containerId}`]
        : []
    }

    const organizationIdsToRemove: string[] = restrictedOrganizationIds(
      newArrayOfTypes
    )
    if (organizationIdsToRemove.length) {
      newFileData[index].viewable_by = newFileData[index].viewable_by.filter(
        (x) => !organizationIdsToRemove.includes(x)
      )
    }

    if (!bookingLvl) {
      newFileData[index].booking = ''
    } else if (!newFileData[index].booking) {
      newFileData[index].booking = toString(props.bookingId) || ''
    }

    setFileData(newFileData)
  }

  const handleContainerChange = (
    newContainerValue: string[],
    index: number
  ) => {
    const newFileData: IFileData[] = [...fileData]
    newFileData[index].containers = newContainerValue
    setFileData(newFileData)
  }

  const handleViewableChange = (newViewableValue: string[], index: number) => {
    const newFileData: IFileData[] = [...fileData]
    newFileData[index].viewable_by = newViewableValue
    setFileData(newFileData)
  }

  const handleShipperChange = (newShipperValue, index) => {
    const newFileData: IFileData[] = [...fileData]
    newFileData[index].booking = newShipperValue
    setFileData(newFileData)
  }

  const getContainerLevelsArr = (): number[] => {
    const mandatory = (props.documentTypes || []).map((sub: IDocType) => {
      return sub.container_level ? sub.id : null
    })
    return without(mandatory, null) as number[]
  }

  const getBookingsLevelsArr = (): number[] => {
    const mandatory = (props.documentTypes || []).map((sub: IDocType) => {
      return sub.booking_level ? sub.id : null
    })
    return without(mandatory, null) as number[]
  }

  const checkDisabledContainer = (data: IFileData): boolean => {
    if (data.types && data.types.length) {
      const docTypes = props.documentTypes.filter((x) => {
        return includes(data.types, `${x.id}`)
      })

      if (!docTypes.length) {
        return true
      }
      const shipmentLvl: boolean = !!docTypes.filter((x) => x.shipment_level)
        .length
      const containerLvl: boolean = !!docTypes.filter((x) => x.container_level)
        .length

      switch (true) {
        case !shipmentLvl && containerLvl:
          return false
        case shipmentLvl && !containerLvl:
          return true
        case shipmentLvl && containerLvl:
          return false
        default:
          return true
      }
    } else {
      return true
    }
  }

  const checkDisabled = (): boolean => {
    const data: IFileData[] = [...fileData]

    if (data && data.length) {
      const checkByType = !!data.filter(
        (x) => !x.types.filter((y) => !!y).length
      ).length

      const emptyContainers = data.filter((sub: IFileData) => {
        return !sub.containers.length
      })
      const containerTypeIds: number[] = getContainerLevelsArr()

      const mandatoryContainers = emptyContainers.map((sub: IFileData) => {
        return (
          (sub.types &&
            !!sub.types.filter((element) =>
              containerTypeIds.includes(toNumber(element))
            ).length) ||
          false
        )
      })

      const emptyBookings = data.filter((sub: IFileData) => {
        return !sub.booking
      })
      const bookingTypeIds: number[] = getBookingsLevelsArr()

      const mandatoryBookings = emptyBookings.map((sub: IFileData) => {
        return (
          (sub.types &&
            !!sub.types.filter((element) =>
              bookingTypeIds.includes(toNumber(element))
            ).length) ||
          false
        )
      })

      return props.shipment
        ? checkByType ||
            includes(mandatoryContainers, true) ||
            includes(mandatoryBookings, true)
        : checkByType || includes(mandatoryBookings, true)
    } else {
      return true
    }
  }

  const restrictedOrganizationIds = (selectedTypeIds) => {
    let restrictedRoleCodes: string[] = []
    const restrictedIds: string[] = []
    props.documentTypes
      .filter((x) => selectedTypeIds.includes(`${x.id}`))
      .forEach((x) => {
        restrictedRoleCodes = restrictedRoleCodes.concat(
          x.restricted_role_codes
        )
      })
    props.parties.forEach((organization) => {
      if (restrictedRoleCodes.includes(organization.organization_role_code)) {
        restrictedIds.push(`${organization.id}`)
      }
    })
    return restrictedIds
  }

  const disabledOptionsForViewable = (selectedTypeIds) => {
    return [`${organizationId}`].concat(
      restrictedOrganizationIds(selectedTypeIds)
    )
  }

  const extendedOptions = (options, type) => {
    switch (type) {
      case 'containers':
        return options?.map((option) => {
          option.name =
            option.container_number ||
            option.number ||
            option.container_index ||
            option.number ||
            option.id
          return option
        })
      case 'bookings':
        return orderBy(options, ['id'], ['asc']).map((option) => {
          option.name = option.shipper?.shipment_address
            ? option.shipper?.shipment_address?.name
            : option.booking_index
          return option
        })
      case 'viewable_by':
        return orderBy(options || [], ['organization_id'], ['asc'])
    }
  }

  const containersOptions = () => {
    return extendedOptions(props.containers, 'containers') || []
  }

  const bookingDisabled = (data: IFileData): boolean => {
    if (data.types && data.types.length) {
      const docTypes = props.documentTypes.filter((x) => {
        return includes(data.types, `${x.id}`)
      })

      return !docTypes.filter((x) => x.booking_level).length
    } else {
      return true
    }
  }

  const partiesSelectOptions = useMemo(() => {
    return (
      extendedOptions(props.parties, 'viewable_by')?.map(
        (party: IShipmentParty) => ({
          label: party.name,
          id: `${party.organization_id}`,
        })
      ) || []
    )
  }, [props.parties])

  const typesSelectOptions = useMemo(() => {
    return (
      props.documentTypes?.map((documentType: IDocType) => ({
        translationKey: documentType.translationKey,
        label: documentType.name,
        id: String(documentType.id),
        group: 'components.autocomplete.file_upload.documents_group_header',
      })) || []
    )
  }, [props.documentTypes])

  const containerSelectOptions = useMemo(() => {
    return containersOptions().map(
      (container: { name: string; id: number }) =>
        ({
          label: container.name,
          id: `${container.id}`,
        } || [])
    )
  }, [props.containers])

  const shipperSelectOptions = useMemo(() => {
    return (
      extendedOptions(props.bookings, 'bookings')?.map(
        (option: { id: number; name: string }) => ({
          id: `${option.id}`,
          label: option.name,
        })
      ) || []
    )
  }, [props.bookings])

  const isAirModality = shipmentModality === ModalityEnum.Air

  const bookingViewable = permissionTo('shipments.bookings.view')

  const getPartiesOptions = (sub: IFileData) =>
    partiesSelectOptions?.map(
      (option) =>
        ({
          ...option,
          disabled: disabledOptionsForViewable(sub.types).includes(option.id),
        } || [])
    )

  const getSelectedDocumentTypes = (sub: IFileData) =>
    typesSelectOptions.filter((documentType) =>
      sub.types.includes(`${documentType.id}`)
    )

  const getNumberOfColumns = () => {
    let colNumber = 5
    if (isAirModality) {
      colNumber -= 1
    }
    if (!bookingViewable) {
      colNumber -= 2
    }
    return colNumber
  }

  const columnWidth = useMemo(() => {
    return `${Math.round(100 / getNumberOfColumns())}%`
  }, [isAirModality, bookingViewable])

  const filesBlocks = () => {
    return (
      <>
        {fileData &&
          !!fileData.length &&
          fileData.map((sub: IFileData, index: number) => (
            <div
              className={`common-upload-files__row ${
                fileData.length > 1 ? 'underlined' : ''
              }`}
              key={index}
            >
              <Stack
                spacing={1}
                direction="row"
                sx={{ flexGrow: 1 }}
                data-testid="shipment-document-upload-modal-row"
              >
                <Box width="100%">
                  <Stack spacing={1} direction="row" maxWidth={1000}>
                    <Box width={columnWidth} display="flex" alignItems="end">
                      <Typography
                        noWrap={true}
                        component="div"
                        lineHeight="40px"
                        children={sub.name}
                        data-testid="shipment-document-upload-modal-row-file-name"
                      />
                    </Box>
                    <Box
                      width={columnWidth}
                      data-testid="shipment-document-upload-modal-row-types"
                    >
                      <AutocompleteCommonRecentOptionsWrapper
                        storeKey="autocomplete-documents-upload-recent-options"
                        label="Types"
                        options={typesSelectOptions}
                        onReset={() => {
                          clearAllTypes(index)
                        }}
                        onChange={(value) => {
                          handleSelectTypeChange(value, index)
                        }}
                        disablePortal={false}
                        value={getSelectedDocumentTypes(sub)}
                        placeholder={t('common.select', 'Select')}
                      />
                    </Box>
                    {!isAirModality && (
                      <Box
                        width={columnWidth}
                        data-testid="shipment-document-upload-modal-row-containers"
                      >
                        <MultiSelect
                          allSelectable
                          label={t(
                            'shipment_documents.fields.containers.label',
                            'Containers'
                          )}
                          placeholder={t('common.select', 'Select')}
                          options={containerSelectOptions}
                          value={sub.containers}
                          onReset={() => {
                            clearAllTypes(index)
                          }}
                          onChange={(event) => {
                            handleContainerChange(
                              event.target.value as string[],
                              index
                            )
                          }}
                          onSelectAll={(selected) => {
                            handleContainerChange(selected as string[], index)
                          }}
                          disabled={checkDisabledContainer(sub)}
                        />
                      </Box>
                    )}
                    {bookingViewable && (
                      <>
                        <Box
                          width={columnWidth}
                          data-testid="shipment-document-upload-modal-row-shipper"
                        >
                          <SingleSelect
                            label={t(
                              'shipment_documents.fields.shipper.label',
                              'Shipper'
                            )}
                            placeholder={t('common.select', 'Select')}
                            onChange={(event) => {
                              handleShipperChange(event.target.value, index)
                            }}
                            options={shipperSelectOptions}
                            value={sub.booking}
                            disabled={bookingDisabled(sub)}
                          />
                        </Box>
                        <Box
                          width={columnWidth}
                          data-testid="shipment-document-upload-modal-row-parties"
                        >
                          <MultiSelect
                            label={t(
                              'shipment_documents.fields.parties.label',
                              'Parties'
                            )}
                            placeholder={t('common.select', 'Select')}
                            options={getPartiesOptions(sub)}
                            value={sub.viewable_by}
                            onReset={() => {
                              clearViewable(index, sub.types)
                            }}
                            onChange={(event) => {
                              handleViewableChange(
                                event.target.value as string[],
                                index
                              )
                            }}
                          />
                        </Box>
                      </>
                    )}
                  </Stack>
                </Box>
                {!props.isEditDocument && fileData.length > 1 && (
                  <Box sx={{ maxWidth: 20 }} display="flex" alignItems="end">
                    <Box height={40} display="flex" alignItems="center">
                      <IconButton
                        size="small"
                        onClick={() => {
                          removeFile(index)
                        }}
                        data-testid="shipment-document-upload-modal-row-delete-file"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                )}
              </Stack>
            </div>
          ))}
      </>
    )
  }

  return (
    <Modal.Window open={props.isOpen} onClose={props.onClose}>
      <Modal.Title
        children={
          props.isEditDocument
            ? t('upload_files.edit', 'Edit documents')
            : t('upload_files.upload', 'Upload documents')
        }
        onClose={props.onClose}
      />
      <Modal.Content>
        <div className="common-upload-files">
          {!props.busyData && filesBlocks()}
          {props.busyData && <CircularProgress />}
        </div>
      </Modal.Content>
      <Modal.Actions>
        {!props.isEditDocument && (
          <Button
            onClick={submitAndAdd}
            variant="outlined"
            disabled={checkDisabled()}
          >
            {t(
              'common.buttons.submit_and_upload',
              'Submit and upload new document'
            )}
          </Button>
        )}
        <Button
          variant="contained"
          onClick={submit}
          disabled={checkDisabled()}
          data-testid="shipment-document-upload-modal-submit"
        >
          {t('common.buttons.submit', 'Submit')}
        </Button>
      </Modal.Actions>
    </Modal.Window>
  )
}

export default UploadFiles
