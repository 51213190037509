import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import { Box } from '@mui/material'
import { permissionTo } from '../../../utils'
import './styles.scss'

interface IProps {
  title: string
  service: InlandTransportService
  data: IInlandTransportAddress | null
  containerAddress?: boolean
}

const AddressInfoBlock: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const managePermission: boolean = permissionTo(
    `shipments.containers_|_cargo.${props.service}.manage`
  )
  const cargoPermission: boolean = permissionTo(
    'shipments.containers_|_cargo.cargo_lines.manage'
  )

  const shortify = (value): string => {
    return value.length > 20 ? `${value.substring(0, 20)}...` : value
  }

  return (
    <Box data-testid="address-info-block" display="flex" flexDirection="column">
      {managePermission && cargoPermission && (
        <CreateRoundedIcon
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            width: '16px',
            height: '16px',
          }}
        />
      )}
      <Typography
        pb={2}
        variant="h5"
        children={
          props.service === 'pickup'
            ? t('shipment_containers.pickup', 'Pick-up')
            : t('shipment_containers.delivery', 'Delivery')
        }
      />
      <div className="address-info-block--container">
        <div className="address-title ">
          <Typography variant="body1Strong" children={props.title} />
        </div>
        <div className="address-details">
          {props.data?.address_id && (
            <div>
              <div className="address-details--name">
                <Typography
                  variant="body1"
                  children={shortify(props.data.name || '-')}
                />
              </div>
              <div className="address-details--address">
                <Typography
                  variant="body1"
                  children={props.data.address || '-'}
                />
              </div>
              <div>
                <Typography variant="body1">
                  {props.data.postal_code || '-'} {props.data.city || '-'}
                </Typography>
              </div>
              <div>
                <Typography
                  variant="body1"
                  children={props.data.country?.name || '-'}
                />
              </div>
            </div>
          )}
          {!props.data?.address_id && (
            <Typography
              variant="body1"
              children={t('shipment_containers.address_not_set', 'Not set yet')}
            />
          )}
        </div>
      </div>
      <div className="address-info-block--container">
        <div className="address-title paragraph__small grey">
          <Typography
            variant="body1Strong"
            children={
              props.service === 'pickup'
                ? t('shipment_containers.pickup_reference', 'Pick-up reference')
                : t(
                    'shipment_containers.delivery_reference',
                    'Delivery reference'
                  )
            }
          />
        </div>
        <div className="address-details">
          <Typography
            variant="body1"
            children={shortify(
              props.data?.reference ||
                t('shipment_containers.address_not_set', 'Not set yet')
            )}
          />
        </div>
      </div>
    </Box>
  )
}

export default AddressInfoBlock
