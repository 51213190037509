import { useEffect } from 'react'
import { useGetQuickFilters } from 'src/services/Api/savedFilters'
import { overviewPageName, pageClassName } from './constants'
import OverviewContent from './Content'
import './index.scss'

const Overview = () => {
  const { isFetching } = useGetQuickFilters(
    {
      page: overviewPageName,
      per_page: 100,
    },
    {
      refetchOnMount: false,
    }
  )

  useEffect(() => {
    document.body.classList.add(pageClassName)

    return () => {
      document.body.classList.remove(pageClassName)
    }
  }, [])

  if (isFetching) {
    return null
  }

  return <OverviewContent />
}

export default Overview
