import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import { queryKeys } from 'src/services/endpoints'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  tableStorageKey,
  savedViewLocalStorageKey,
} from 'src/components/Overview/constants'
import {
  setLocalStorage,
  getLocalStorage,
  getStorageKeys,
} from 'src/components/Common/Table/DataTable/TableWrapper.utils'

import { overviewPageName, defaultValues } from 'src/pages/Overview/constants'
import { useFormContext } from 'react-hook-form'
import { queryClient } from 'src/services/http-common'
import { ISavedFilter } from 'src/@types/endpoints/other'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  useCreateNewFilter,
  useGetQuickFilters,
  useDeleteSavedFilter,
  useUpdateSavedFilter,
} from 'src/services/Api/savedFilters'
import { savedFilterKey } from '../Search/constants'
import { getNewView } from '../utils'
import OverviewViewsList from './OverviewViewsList'
import OverviewViewsAdd from './OverviewViewsAdd'
import OverviewViewsDelete from './OverviewViewsDelete'

const OverviewViews = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { data: views = [] } = useGetQuickFilters(
    {
      page: overviewPageName,
    },
    {
      refetchOnMount: false,
      retry: false,
    }
  )
  const storageKeys = getStorageKeys(tableStorageKey)
  const savedViewId = getLocalStorage(savedViewLocalStorageKey, null)
  const savedView = views.find((view) => view.id === savedViewId)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [addViewOpen, setAddViewOpen] = useState(false)
  const [deleteViewOpen, setDeleteViewOpen] = useState(false)
  const [renameViewOpen, setRenameViewOpen] = useState(false)
  const { getValues } = useFormContext()

  const { mutateAsync: createNewFilter } = useCreateNewFilter()
  const { mutateAsync: deleteFilter } = useDeleteSavedFilter()
  const { mutateAsync: updateSavedFilter } = useUpdateSavedFilter(savedViewId)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const onAddViewClose = () => {
    setAddViewOpen(false)
  }

  const onRenameViewClose = () => {
    setRenameViewOpen(false)
  }

  const onDeleteViewClose = () => {
    setDeleteViewOpen(false)
  }

  const onAddViewClick = () => {
    setAddViewOpen(true)
    handleClose()
  }

  const onRenameViewClick = () => {
    setRenameViewOpen(true)
    handleClose()
  }

  const onDeleteViewClick = () => {
    setDeleteViewOpen(true)
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'views-button-label' : undefined
  const filter = getValues()

  const onSave = (name: string) => {
    const requestBody = getNewView(name, filter)

    createNewFilter(requestBody)
      .then((response) => {
        queryClient.setQueriesData(
          [queryKeys.savedFilters],
          (oldParams: any) => {
            if (!oldParams) return oldParams

            const savedViews = oldParams ?? []
            return [response, ...savedViews]
          }
        )
        setLocalStorage(savedViewLocalStorageKey, response.id)
      })
      .finally(() => {
        onAddViewClose()
      })
  }

  const onRename = (name: string) => {
    const requestBody = getNewView(name, filter)
    updateSavedFilter(requestBody)
      .then((res) => {
        queryClient.setQueriesData(
          [queryKeys.savedFilters],
          (oldViews: any) => {
            if (!oldViews) return []
            return oldViews.map((view: ISavedFilter) => {
              if (view.id === savedViewId) {
                return res
              }
              return view
            })
          }
        )
      })
      .finally(onRenameViewClose)
  }

  const onClick = (view: ISavedFilter) => {
    const { filter, table } = view
    if (table) {
      setLocalStorage(
        storageKeys['columnVisibility'],
        table?.columnVisibility ?? {}
      )
      setLocalStorage(storageKeys['columnPinning'], table?.columnPinning ?? {})
      setLocalStorage(storageKeys['columnSizing'], table?.columnSizing ?? {})
      setLocalStorage(storageKeys['columnOrder'], table?.columnOrder ?? {})
      setLocalStorage(storageKeys['columnSorting'], table?.columnSorting ?? [])
      setLocalStorage(storageKeys['density'], table?.density ?? 'compact')
    }

    if (filter) {
      setLocalStorage(savedFilterKey, filter)
    }

    setLocalStorage(savedViewLocalStorageKey, view.id)

    history.go(0)
  }

  const onDelete = () => {
    if (savedView?.id) {
      const deletedViewId = savedView.id
      deleteFilter(deletedViewId)
        .then(() => {
          setLocalStorage(savedFilterKey, defaultValues)
          localStorage.removeItem(storageKeys['columnVisibility'])
          localStorage.removeItem(storageKeys['columnPinning'])
          localStorage.removeItem(storageKeys['columnSizing'])
          localStorage.removeItem(storageKeys['columnOrder'])
          localStorage.removeItem(storageKeys['columnSorting'])
          localStorage.removeItem(storageKeys['density'])
          queryClient.setQueriesData(
            [queryKeys.savedFilters],
            (oldViews: any) => {
              if (!oldViews) return []

              return oldViews.filter(
                (view: ISavedFilter) => view.id !== deletedViewId
              )
            }
          )
          history.go(0)
        })
        .finally(onDeleteViewClose)
    }
  }

  return (
    <>
      <Button
        id={id}
        variant="text"
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      >
        {savedView?.name ?? t('overview_views.actions.all', 'All shipments')}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        data-testid="search-group-popover"
      >
        <Box width={300} py={1}>
          <OverviewViewsList
            views={views}
            savedView={savedView}
            onSaveClick={onAddViewClick}
            onRenameClick={onRenameViewClick}
            onDeleteClick={onDeleteViewClick}
            onItemClick={onClick}
          />
        </Box>
      </Popover>
      <OverviewViewsAdd
        open={addViewOpen}
        onClose={onAddViewClose}
        onSave={onSave}
        title={t('overview_views.add_view.title', 'Add view')}
        description={t(
          'overview_views.add_view.description',
          'Your filters, grouping, and sorting will be saved as a new view.'
        )}
      />
      <OverviewViewsAdd
        open={renameViewOpen}
        onClose={onRenameViewClose}
        onSave={onRename}
        defaultValue={savedView?.name ?? ''}
        title={t('overview_views.edit_view.title', 'Edit view')}
        description={t(
          'overview_views.edit_view.description',
          'You can edit the name of the view. Your other page settings will be saved automatically.'
        )}
      />
      <OverviewViewsDelete
        open={deleteViewOpen}
        onClose={onDeleteViewClose}
        onDelete={onDelete}
      />
    </>
  )
}

export default OverviewViews
